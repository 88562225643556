

 @font-face {
  font-family: "KhmerOSSiemreap";
  src: url('assets/fonts/KHMEROSSIEMREAP.TTF');
}

body, html {
  font-family: 'KhmerOSSiemreap' !important;
  background-color: #ddd!important;
 }

 .remove-class{
  color: red!important;
  font-size: 16!important;
}

.zoom-bigger{
  transition:all .3s ease;
}

.zoom-bigger:hover{
  transform:translateZ(0) scale(1.4);
}
.viewResult path{
  stroke: white !important;
}
.loading-data{
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-add-report{
 margin-top: -20px !important;
}

.ant-btn-dashed{
  color: #1890ff !important;
  height: 30px !important;
  width: 90px !important;
  padding: 5px !important;
  font-size: 12px !important;
}

.login-form{
  width: 100%!important;
  height: 100vh!important;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.Login-user-icon{
  width: 100%;
  font-size: 50pt;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  color: #1890ff;
}