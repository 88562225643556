@import url('https://fonts.googleapis.com/css2?family=Bayon&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.customize-drawer .ant-drawer-body{
    background-color: #ddd!important;
}

.customize-form .ant-form-item-label label{
    font-size: 16px!important;
}

.ant-select-item{
    font-size: 16px!important;
    overflow: none;
}

